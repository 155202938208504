import React, { useState, useEffect } from 'react'
import { object, string } from 'yup'
import { useFormik } from 'formik'
import AsyncSelect from 'react-select/async'
import dayjs from 'dayjs'
import { countries } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Address, orders, ShoppingCartAddress, ShoppingCartProduct } from '../../types'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { resetUserMessage, updateUserAddress } from '../../store/reducers/api/usersReducer'
import { createCatalogueOrders, resetPendingOrderError, resetPendingOrderMessage } from '../../store/reducers/api/pendingOrderQueueReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { ORDER_CREATION_SUCCESS_MESSAGE, ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'
import { getMyProfile } from '../../store/reducers/api/profileReducer'
import Progress from '../loaders/Progress'

interface AddressEditorProps {
  billingAddress: Partial<Address> | null
  loadCostCenterOptionsDebounced: (...args: any[]) => void
  shippingMode: 'inventory' | 'delivery' | null
  setSelectedBillingAddress: React.Dispatch<React.SetStateAction<Partial<Address> | null>>
}

const ShoppingPayment = ({
  billingAddress,
  loadCostCenterOptionsDebounced,
  shippingMode,
  setSelectedBillingAddress
}: AddressEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiUsers.isLoading)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)
  const address = useAppSelector((state) => state.apiUsers.address)
  const isLoading = useAppSelector((state) => state.apiPendingOrderQueue.isLoading)
  const message = useAppSelector((state) => state.apiPendingOrderQueue.message)
  const error = useAppSelector((state) => state.apiPendingOrderQueue.error)
  const userMessage = useAppSelector((state) => state.apiUsers.message)

  const [paymentMode, setPaymentMode] = useState('invoice')
  const [hasAgreed, setHasAgreed] = useState<'no' | 'yes'>('no')
  const [canSaveAddress, setCanSaveAddress] = useState<'no' | 'yes'>('no')

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const addressSchema = object({
    companyName: string()
      .min(2, 'Enter a valid company name')
      .max(32, 'Company Name is too long'),
    email: string()
      .email('Enter a valid email')
      .required('Email is required'),
    vat: string()
      .nullable()
      .max(16, 'Phone number is too long'),
    country: string().required('Country is required').oneOf(countries),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string()
      .label('Zip')
      .required('Zip is required')
      .max(32, 'Zip is too long'),
    addressAddition: string(),
    costCenter: string(),
    hasAgreed: string().oneOf(['yes'], 'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.')
  })

  const saveBillingAddress = (id: string, token: string, address: Omit<Address, 'id'> & {
    affiliation: string}, signal: AbortSignal) => {
    dispatch(updateUserAddress({ id, token, address, signal }))
  }

  const createOrder = (token: string, pendingOrders: orders.PendingOrder[], signal: AbortSignal) => {
    dispatch(createCatalogueOrders({ token, pendingOrders, signal }))
  }

  const foundLeftOverProduct = (productId: string) => {
    return shoppingCartLeftOverProducts.find(product => product.id === productId)
  }
  const getProduct = (productId: string) => {
    return shoppingCartProducts.find(product => product.id === productId)
  }
  const generatePendingOrders = (billingAddress: Omit<Address, 'id'>): Array<orders.PendingOrder> => {
    const createAddressRequest = (address: Partial<Address>) => ({
      salutation: address.salutation ?? '',
      firstName: address.firstName ?? '',
      lastName: address.lastName ?? '',
      title: address.title ?? '',
      company: address.companyName ?? '',
      companyAddition: '',
      street: address.street ?? '',
      addressAddition: address.addressAddition ?? '',
      zipCode: address.zip ?? '',
      place: address.city ?? '',
      phone: address.phone ?? '',
      state: '',
      country: address.country ?? '',
      iso: '',
      telephone: '',
      mobile: '',
      fax: '',
      email: address.email ?? '',
      costCenter: address.costCenter ?? ''
    })

    const createBaseOrder = (cartAddress: ShoppingCartAddress) => ({
      currency: 'EUR',
      orderNo: '0',
      shippingId: 7,
      shipped: dayjs(cartAddress.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(5, 'minutes').format(),
      deliverydate: dayjs(cartAddress.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(5, 'minutes').format(),
      note: cartAddress.note,
      description: '',
      costCenter: billingAddress.costCenter ? String(billingAddress.costCenter) : '',
      shippingAddressRequests: [createAddressRequest(cartAddress)],
      billingAddressRequests: [createAddressRequest(billingAddress)]
    })

    const createOrderLineRequest = (product: ShoppingCartProduct, quantity: number) => ({
      itemName: product.name,
      articleNumber: String(product.merchantSku),
      itemNetSale: 0,
      itemVAT: 0,
      quantity,
      type: 0,
      discount: 0,
      netPurchasePrice: 0
    })

    if (isBulkOrder) {
      const mappedBulkOrders = shoppingCartAddresses.map(cartAddress => ({
        ...createBaseOrder(cartAddress),
        orderLineRequests:
          cartAddress.assignedBulkProductsIds.length > 0
            ? cartAddress.assignedBulkProductsIds
              .map(productId => getProduct(productId))
              .filter((product): product is ShoppingCartProduct => product !== undefined)
              .map(product => createOrderLineRequest(product, Number(product.quantity || 1)))
            : shoppingCartProducts.map(product => createOrderLineRequest(product, Number(product.quantity || 1)))
      }))
      return mappedBulkOrders
    } else {
      const mappedAdditionalArticleOrders = shoppingCartAddresses.map(cartAddress => ({
        ...createBaseOrder(cartAddress),
        orderLineRequests: cartAddress.assignedLeftOverProductIds
          .map(assignedProductId => foundLeftOverProduct(assignedProductId))
          .filter((product): product is NonNullable<typeof product> => product !== undefined)
          .map(product => createOrderLineRequest(product, Number(product.quantity || 1)))
      }))

      const mappedBundleOrders = shoppingCartAddresses.flatMap((cartAddress) =>
        cartAddress.assignedBundleIds.flatMap((bundleId) => {
          const bundle = shoppingCartBundles.find(bundle => bundle.id === bundleId)
          if (!bundle) return []
          const allProductsHaveQuantityOne = bundle.products.every(product => Number(product.quantity || 1) === 1)
          const orderCount = allProductsHaveQuantityOne ? 1 : (bundle.quantity || 1)

          return Array.from({ length: orderCount }).map(() => ({
            ...createBaseOrder(cartAddress),
            orderLineRequests: bundle.products.map((product) =>
              createOrderLineRequest(product, allProductsHaveQuantityOne ? bundle.quantity : Number(product.quantity || 1))
            )
          }))
        })
      )

      return [...mappedAdditionalArticleOrders, ...mappedBundleOrders]
        .filter((order) => order.orderLineRequests.length > 0)
    }
  }

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      ...billingAddress,
      companyName: billingAddress?.companyName ?? '',
      vat: billingAddress?.vat ?? '',
      addressAddition: billingAddress?.addressAddition ?? '',
      costCenter: billingAddress?.costCenter ?? '',
      email: billingAddress?.email ?? '',
      country: billingAddress?.country ?? '',
      city: billingAddress?.city ?? '',
      street: billingAddress?.street ?? '',
      zip: billingAddress?.zip ?? '',
      hasAgreed,
      canSaveAddress
    },
    onSubmit: (
      {
        companyName,
        email,
        vat,
        country,
        city,
        street,
        zip,
        addressAddition,
        costCenter
      },
      actions
    ) => {
      const controller = new AbortController()
      const signal = controller.signal
      const address: Omit<Address, 'id'> & { affiliation: string } = {
        companyName: companyName || null,
        email,
        phone: null,
        vat: vat || null,
        country,
        city,
        street,
        zip,
        addressAddition: addressAddition || null,
        costCenter: costCenter || null,
        type: 'billing',
        affiliation: 'personal'
      }

      setSelectedBillingAddress(address)

      if (canSaveAddress === 'yes' && token && currentUser.id) {
        saveBillingAddress(currentUser.id, token, address, signal)
      }
      if (token && shippingMode === 'delivery') {
        const pendingOrders = generatePendingOrders(address)
        if (pendingOrders.length > 0) {
          createOrder(token, pendingOrders, signal)
        } else {
          const toastPayload = {
            title: 'Warning',
            message: 'Please select a product',
            isVisible: true,
            timestamp: dayjs().format('LT'),
            type: 'warning'
          }
          dispatch(setToast(toastPayload))
        }
      }
      actions.setSubmitting(false)
    },
    validationSchema: addressSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    if (message === ORDER_CREATION_SUCCESS_MESSAGE) {
      const toastPayload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(toastPayload))
      dispatch(resetPendingOrderMessage())
      dismissModal('shoppingPaymentModal')
      openModal('shoppingOrderConfirmationModal')
      resetForm()
    }
  }, [message])

  useEffect(() => {
    if (error && error.errors && error.errors.message) {
      const toastPayload = {
        title: 'Error',
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(resetPendingOrderError())
      dispatch(setToast(toastPayload))
    }
  }, [error])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const allowedMessages = [ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE]

    if (token && (allowedMessages.includes(userMessage) && address?.type === 'billing')) {
      dispatch(getMyProfile({ token, signal }))
      dispatch(resetUserMessage())
    }
  }, [userMessage])

  return (
    <div>
      <div className="row justify-content-center m-0">
        <div className="col-lg-7 col-md-10 col-sm-11">
          <div
            className="horizontal-steps mt-4 mb-4 pb-5"
            id="tooltip-container"
          >
            <div className="horizontal-steps-content">
              <div className="step-item">
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Your Cart
                </span>
              </div>
              <div className="step-item">
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Shipping Details
                </span>
              </div>
              <div className="step-item current">
                <span>Payment</span>
              </div>
            </div>
            <div className="process-line" style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-lg-7">
            <h6>Billing Address</h6>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="billingCompanyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, companyName: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.companyName}
                    type="text"
                    className={`form-control ${
                      errors.companyName &&
                      touched.companyName &&
                      errors.companyName
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingCompanyName"
                    name="companyName"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryCompanyNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.companyName}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingEmail" className="form-label">
                    Email <span className="small text-primary">(for receipt of invoice)</span>
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, email: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email && errors.email
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingEmail"
                    name="email"
                    placeholder=""
                    autoComplete="off"
                  />
                  <div
                    id="validationBillingEmailFeedback"
                    className="invalid-feedback"
                  >
                    {errors.email}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingUSTID" className="form-label">
                    USTID
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, vat: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.vat}
                    type="text"
                    className={`form-control ${
                      errors.vat && touched.vat && errors.vat
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingUSTID"
                    name="vat"
                    placeholder=""
                    autoComplete="off"
                  />
                  <div
                    id="validationDeliveryVatFeedback"
                    className="invalid-feedback"
                  >
                    {errors.phone}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingCountry" className="form-label">
                    Country
                  </label>
                  <select
                    aria-label="Country"
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, country: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.country}
                    className={`form-select ${
                      errors.country && touched.country && errors.country
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingCountry"
                    name="country"
                    autoComplete="off"
                  >
                    <option value="">Select Country</option>
                    {countries.map((country: string, index: number) => (
                      <option key={index}>{country}</option>
                    ))}
                  </select>
                  <div
                    id="validationDeliveryCountryFeedback"
                    className="invalid-feedback"
                  >
                    {errors.country}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingCity" className="form-label">
                    City
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, city: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.city}
                    type="text"
                    className={`form-control ${
                      errors.city && touched.city && errors.city
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingCity"
                    name="city"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryCityFeedback"
                    className="invalid-feedback"
                  >
                    {errors.city}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingStreet" className="form-label">
                    Street and House Number
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, street: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.street}
                    type="text"
                    className={`form-control ${
                      errors.street && touched.street && errors.street
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingStreet"
                    name="street"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryStreetFeedback"
                    className="invalid-feedback"
                  >
                    {errors.street}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingZip" className="form-label">
                    Zip
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, zip: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.zip}
                    type="text"
                    className={`form-control ${
                      errors.zip && touched.zip && errors.zip ? 'is-invalid' : ''
                    }`}
                    id="billingZip"
                    name="zip"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryZipFeedback"
                    className="invalid-feedback"
                  >
                    {errors.zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="billingAddressAddition"
                    className="form-label"
                  >
                    Address Addition
                  </label>
                  <input
                    onChange={(event) => setSelectedBillingAddress(billingAddress => ({ ...billingAddress, addressAddition: event.target.value }))}
                    onBlur={handleBlur}
                    value={values.addressAddition}
                    type="text"
                    className={`form-control ${
                      errors.addressAddition &&
                      touched.addressAddition &&
                      errors.addressAddition
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingAddressAddition"
                    name="addressAddition"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryAddressAdditionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.addressAddition}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingCostCenter" className="form-label">
                    Cost Center
                  </label>
                  <AsyncSelect
                    inputId="billingCostCenter"
                    cacheOptions
                    loadOptions={loadCostCenterOptionsDebounced}
                    defaultOptions
                    onChange={(selectedOption: any) => {
                      setFieldValue('costCenter', selectedOption.value)
                      setSelectedBillingAddress(billingAddress => ({ ...billingAddress, costCenter: selectedOption.value }))
                    }}
                    placeholder="Select Cost Center"
                    className={`${
                      errors.costCenter && touched.costCenter ? 'is-invalid' : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor:
                          errors.costCenter && touched.costCenter
                            ? '#dc3545'
                            : provided.borderColor,
                        '&:hover': {
                          boxShadow:
                            errors.costCenter && touched.costCenter
                              ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)'
                              : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                          borderColor:
                            errors.costCenter && touched.costCenter
                              ? '#dc3545'
                              : '#86b7fe'
                        }
                      })
                    }}
                    value={{ value: values.costCenter, label: values.costCenter }}
                  />
                  <div
                    id="validationDeliveryCostCenterFeedback"
                    className="invalid-feedback"
                  >
                    {errors.costCenter}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <p className="mt-2 mb-1">
                    Save the billing address
                  </p>
                  <div className={`form-check form-check-inline ${errors.canSaveAddress ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.canSaveAddress ? 'is-invalid' : ''}`}
                      type="radio"
                      name="canSaveAddress"
                      id="inlineRadioYes"
                      onChange={() => { setCanSaveAddress('yes') }}
                      value="yes"
                      autoComplete="off"
                      checked={values.canSaveAddress === 'yes'}
                    />
                    <label className="form-check-label" htmlFor="inlineRadioYes">Yes</label>
                  </div>
                  <div className={`form-check form-check-inline ${errors.canSaveAddress ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.canSaveAddress ? 'is-invalid' : ''}`}
                      type="radio"
                      name="canSaveAddress"
                      id="inlineRadioNo"
                      onChange={() => { setCanSaveAddress('no') }}
                      value="no"
                      autoComplete="off"
                      checked={values.canSaveAddress === 'no'}
                    />
                    <label className="form-check-label" htmlFor="inlineRadioNo">No</label>
                  </div>
                  <div
                    id="validationCanSaveAddressFeedback"
                    className="invalid-feedback"
                  >
                    {errors.canSaveAddress}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className='mb-3'>
              <h6>Select Payment Method</h6>
            </div>
            <div className="row border rounded m-0">
              <div className="col">
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="inventoryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setPaymentMode('invoice')
                      }
                    }}
                    checked={paymentMode === 'invoice'}
                  />
                  <label className="form-check-label" htmlFor="inventoryRadio">
                    Payment via Invoice
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <p className="mt-2">
                    By creating an order, you agree to our <button className="btn btn-link text-primary m-0 p-0" data-bs-toggle="modal" type="button" data-bs-target="#termsModal">terms and conditions</button>.
                  </p>
                  <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                      type="radio"
                      name="hasAgreed"
                      id="inlineBillingAddressRadioYes"
                      onChange={() => setHasAgreed('yes')}
                      value="yes"
                      autoComplete="off"
                      checked={values.hasAgreed === 'yes'}
                    />
                    <label className="form-check-label" htmlFor="inlineBillingAddressRadioYes">Yes</label>
                  </div>
                  <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                      type="radio"
                      name="hasAgreed"
                      id="inlineBillingAddressRadioNo"
                      onChange={() => setHasAgreed('no')}
                      value="no"
                      autoComplete="off"
                      checked={values.hasAgreed === 'no'}
                    />
                    <label className="form-check-label" htmlFor="inlineBillingAddressRadioNo">No</label>
                  </div>
                  <div
                    id="validationHasAgreedFeedback"
                    className="invalid-feedback"
                  >
                    {errors.hasAgreed}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top">
          {isLoading && <Progress />}
          <div className="text-end mt-3">
            <button
              className="btn btn-secondary me-2"
              type="button"
              onClick={() => {
                openModal('shoppingShippingDetailsModal')
                dismissModal('shoppingPaymentModal')
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting || isUpdating || isLoading}
            >
              Confirm Payment
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ShoppingPayment
