import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer'
import toastReducer from './reducers/toastReducer'
import apiAuthReducer from './reducers/api/authReducer'
import profileReducer from './reducers/api/profileReducer'
import apiUsersReducer from './reducers/api/usersReducer'
import apiCompanyReducer from './reducers/api/companyReducer'
import apiRecipientReducer from './reducers/api/recipientReducer'
import apiCardTemplateReducer from './reducers/api/cardTemplateReducer'
import apiCampaignReducer from './reducers/api/campaignReducer'
import apiQuotaReducer from './reducers/api/campaignQuotaReducer'
import apiQuotaNotificationReducer from './reducers/api/campaignQuotaNotificationReducer'
import apiSalutationReducer from './reducers/api/salutationReducer'
import apiBundleReducer from './reducers/api/bundleReducer'
import apiPictureReducer from './reducers/api/pictureReducer'
import apiCostCenterReducer from './reducers/api/costCenterReducer'
import apiProductReducer from './reducers/api/productReducer'
import apiOutboundReducer from './reducers/api/orderReducer'
import apiLegalTextReducer from './reducers/api/legalTextReducer'
import apiAccessPermissionReducer from './reducers/api/accessPermissionReducer'
import apiShippingMethodReducer from './reducers/api/shippingMethodReducer'
import apiGreetingCardReducer from './reducers/api/greetingCardReducer'
import dotnetApiOrderReducer from './reducers/api/dotnet/ordersReducer'
import dotnetApiProductReducer from './reducers/api/dotnet/productReducer'
import pendingOrderReducer from './reducers/api/pendingOrderReducer'
import dotnetApiCustomerReducer from './reducers/api/dotnet/customersReducer'
import dotnetApiStockProductReducer from './reducers/api/dotnet/stockProductReducer'
import apiPendingOrderQueueReducer from './reducers/api/pendingOrderQueueReducer'
import dotnetApiStockFulfillmentReducer from './reducers/api/dotnet/fulfillmentOrderReducer'
import dotnetApiArticlesReducer from './reducers/api/dotnet/articlesReducer'
import dotnetApiHubspotReducer from './reducers/api/dotnet/hubspotReducer'
import dotnetApiCompanyReducer from './reducers/api/dotnet/companyReducer'
import apiCampaignShippingDestinationReducer from './reducers/api/campaignShippingDestinationReducer'
import apiCampaignOrderLimitReducer from './reducers/api/campaignOrderLimitReducer'
import apiEmailTemplateReducer from './reducers/api/emailTemplateReducer'
import apiEmailTemplateTypeReducer from './reducers/api/emailTemplateTypeReducer'
import apiCampaignAddressReducer from './reducers/api/campaignAddressReducer'
import apiMaintenanceModeReducer from './reducers/api/maintenanceModeReducer'
import onboardingReducer from './reducers/onboardingReducer'
import apiProductCategoryReducer from './reducers/api/productCategoryReducer'
import shoppingCartReducer from './reducers/shoppingCartReducer'
import apiProductColorReducer from './reducers/api/productColorReducer'
import apiProductMaterialReducer from './reducers/api/productMaterialReducer'
import apiProductSizeReducer from './reducers/api/productSizeReducer'
import apiProductAccessControlGroupReducer from './reducers/api/productAccessControlGroupReducer'
import apiProductCategoryTagInProductAccessControlGroupReducer from './reducers/api/productCategoryTagInProductAccessControlGroupReducer'
import apiUserInProductAccessControlGroupReducer from './reducers/api/userInProductAccessControlGroupReducer'
import apiCompanyInProductAccessControlGroupReducer from './reducers/api/companyInProductAccessControlGroupReducer'
import apiCompanyUserGroupReducer from './reducers/api/companyUserGroupReducer'
import apiCompanyUserGroupInProductAccessControlGroupReducer from './reducers/api/companyUserGroupInProductAccessControlGroupReducer'
import apiProductCategoryTagReducer from './reducers/api/productCategoryTagReducer'
import apiUserInCompanyUserGroupReducer from './reducers/api/userInCompanyUserGroupReducer'
import apiProductInProductCategoryReducer from './reducers/api/productInProductCategoryReducer'
import apiProductGraduatedPriceReducer from './reducers/api/productGraduatedPriceReducer'
import PreMadeBoxReducer from './reducers/PreMadeBoxReducer'
import apiItemReducer from './reducers/api/wawiAPI/itemReducer'
import dummyProductsReducer from './reducers/api/dummyProductsReducer'
import apiTitleReducer from './reducers/api/titleReducer'

const appReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  apiAuth: apiAuthReducer,
  apiUsers: apiUsersReducer,
  profile: profileReducer,
  apiCompany: apiCompanyReducer,
  apiRecipient: apiRecipientReducer,
  apiCardTemplate: apiCardTemplateReducer,
  apiCampaign: apiCampaignReducer,
  apiQuota: apiQuotaReducer,
  apiQuotaNotification: apiQuotaNotificationReducer,
  apiSalutation: apiSalutationReducer,
  apiBundle: apiBundleReducer,
  apiPicture: apiPictureReducer,
  apiCostCenter: apiCostCenterReducer,
  apiProduct: apiProductReducer,
  apiOutbound: apiOutboundReducer,
  apiLegalText: apiLegalTextReducer,
  apiAccessPermission: apiAccessPermissionReducer,
  apiShippingMethod: apiShippingMethodReducer,
  apiGreetingCard: apiGreetingCardReducer,
  apiPendingOrderQueue: apiPendingOrderQueueReducer,
  apiCampaignShippingDestination: apiCampaignShippingDestinationReducer,
  apiCampaignOrderLimit: apiCampaignOrderLimitReducer,
  dotnetApiOrder: dotnetApiOrderReducer,
  dotnetApiProduct: dotnetApiProductReducer,
  apiPendingOrder: pendingOrderReducer,
  dotnetApiCustomer: dotnetApiCustomerReducer,
  dotnetApiStockProduct: dotnetApiStockProductReducer,
  dotnetApiFulfillmentOrders: dotnetApiStockFulfillmentReducer,
  dotnetApiArticles: dotnetApiArticlesReducer,
  dotnetApiHubspot: dotnetApiHubspotReducer,
  dotnetApiCompany: dotnetApiCompanyReducer,
  apiEmailTemplate: apiEmailTemplateReducer,
  apiEmailTemplateType: apiEmailTemplateTypeReducer,
  apiCampaignAddress: apiCampaignAddressReducer,
  apiMaintenanceMode: apiMaintenanceModeReducer,
  onboarding: onboardingReducer,
  apiProductCategory: apiProductCategoryReducer,
  shoppingCart: shoppingCartReducer,
  apiProductColor: apiProductColorReducer,
  apiProductMaterial: apiProductMaterialReducer,
  apiProductSize: apiProductSizeReducer,
  apiProductAccessControlGroup: apiProductAccessControlGroupReducer,
  apiProductCategoryTagInProductAccessControlGroup: apiProductCategoryTagInProductAccessControlGroupReducer,
  apiUserInProductAccessControlGroup: apiUserInProductAccessControlGroupReducer,
  apiCompanyInProductAccessControlGroup: apiCompanyInProductAccessControlGroupReducer,
  apiCompanyUserGroupInProductAccessControlGroup: apiCompanyUserGroupInProductAccessControlGroupReducer,
  apiCompanyUserGroup: apiCompanyUserGroupReducer,
  apiProductCategoryTag: apiProductCategoryTagReducer,
  apiUserInCompanyUserGroup: apiUserInCompanyUserGroupReducer,
  apiProductInProductCategory: apiProductInProductCategoryReducer,
  apiProductGraduatedPrice: apiProductGraduatedPriceReducer,
  preMadeBoxReducer: PreMadeBoxReducer,
  apiItem: apiItemReducer,
  dummyProducts: dummyProductsReducer,
  apiTitle: apiTitleReducer
})

const reducerProxy = (state: any, action: Action) => {
  if (action.type === 'api/auth/logoutCurrentUser') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: reducerProxy
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
